<template>
    <div :class="androidsFlg == true ? 'login' : 'loginIos'" id="login">
        <rxNavBar title="" androidOrIOSFlag="true" ></rxNavBar>
        <div class="img_title"><img class="indexImg" alt="himi logo" src="../../assets/images/li_logo.png"></div>
        <div class="welcomeDiv">
            <div>欢迎登录，</div>
            <div>海米互联网公寓</div>
        </div>
        <div class="inputPhone">
            <div class="label">+86</div>
            <div class="line">|</div>
            <input ref="moblie" v-model="moblieValue" type="text" onkeyup="this.value=this.value.replace(/\D/g,'')" placeholder="请输入手机号" maxlength="11" @blur="checkMoblie()" />
        </div>
        <div class="inputCode">
            <input type="password" v-model="code" placeholder="请输入密码"/>
        </div>
        <div class="otherLogin">
            <div @click="numLogin" class="codeLogin">验证码登录</div>
            <div class="forgetPwd" @click="goToForgotPwd">忘记密码</div>
        </div>
        <div class="errorDiv">
            <div v-show="errorShow"  class="errorInfo">
                {{msg}}
            </div>
        </div>
        <div class="btnDiv">
            <div class="btn">
                <button class="loginBtn"  type="submit" @click="login">登录</button>
            </div>
        </div>
        <div class="point">
            温馨提示：未注册的用户，登录时将自动注册，且代表您已同意
            <span @click="jumprichText(1)">《用户服务协议》</span>
            <span @click="jumprichText(2)">《隐私协议》</span>
            <span @click="jumprichText(3)">《数字证书使用协议》</span>
        </div>
    </div>
</template>
<script>
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    import {token,version,finalPositioning} from "../../getData/getData";
    import Cookies from 'js-cookie'
    import {checkAndroid, checkIOS, getStaffId, globaluserId, responseUtil} from "../../libs/rongxunUtil";
    /********调用APP方法 begin*********/
    function codeLogin(){
        window.himi.codeLogin();
    }

    function  loginToAndroid(loginInfo) {
        window.himi.login(loginInfo);
    }
    /********调用APP方法 end*********/

    /********判断H5页面是否在web容器中 start*********/
    function openInWebview () {/* 返回true或false; */
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
            return false;
        } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
            return false;
        } else if (ua.match(/WeiBo/i) == "weibo") {
            return false;
        } else {
            if (ua.match(/Android/i) != null) {
                return ua.match(/browser/i) == null;
            } else if (ua.match(/iPhone/i) != null) {
                return ua.match(/safari/i) == null;
            } else {
                return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
            }
        }
    }
    /********判断H5页面是否在web容器中 start*********/

    export default {
        components: {
            rxNavBar
        },
        name:"login",
        data(){
            return {
                androidsFlg: '',
                code : '',
                moblieValue:"",  //手机输入框状态
                errorShow : false,
                msg: '手机号格式有误'
            }
        },
        mounted() {
            this.checkTypeIos()
        },
        methods:{
            checkTypeIos() {
                if(checkAndroid()){
                    this.androidsFlg = true
                }else if(checkIOS()){
                    this.androidsFlg = false
                }
            },
            //登录
            login(){
                // 使用方式
                   //不在原生app打开
                let that = this
                const params = new URLSearchParams();
                let userName =`{"type":"front","userName": ${that.moblieValue}}`
                params.append('username',userName),
                    params.append('password',that.code),
                    params.append('grant_type','password')
                params.append('scope','all')
                /*const params = {};
                let userName =`{"type":"front","userName": ${that.moblieValue}}`
                params.username=userName
                    params.password=that.code
                    params.grant_type="password"
                params.scope="all" */
                token(params).then(function (response) {
                    if(response.data.access_token){
                        that.cokiesAndSkip(response.data,that);
                        let loginInfo = JSON.stringify(response.data);
                        if (openInWebview()) {
                            if(checkAndroid()){
                                try{
                                    loginToAndroid(loginInfo)
                                } catch (e) {
                                    console.log(e.message)
                                }
                            }else if(checkIOS()){
                                window.webkit.messageHandlers.login.postMessage({body: loginInfo});
                            }
                        }
                        that.getVersion()
                    }
                    else{
                        responseUtil.alertMsg(that,'用户名或密码错误')
                    }

                }).catch(function () {
                    responseUtil.alertMsg(that,'用户名或密码错误')
                })
            },
            //获取版本号
            getVersion(){
                let that = this;
                let initData = {}
                version(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.saveFinalPositioning(response.data.data.appVersion)
                    })
                })
            },
            //存储版本号，staffId
            saveFinalPositioning(appVersion){
                let that = this;
                let initData = {}
                initData.location=''
                initData.user_id=globaluserId()
                initData.versionNumber=appVersion
                finalPositioning(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                    })
                })
            },
            //用户登录及跳转
            cokiesAndSkip(data){
                Cookies.set('user', data.staffName)
                Cookies.set('token', data.access_token)
                Cookies.set('user_id', data.user_id)
                Cookies.set('staff_id',data.staff_id)
                //用户登录跳转test
                this.$router.push({
                    name : 'test'
                })
            },
            //用户错误提示
            //忘记密码跳
            goToForgotPwd(){
                this.$router.push({
                    name : 'forgotPassword',
                })
            },

            //跳转富文本页面
            jumprichText(type){
                this.$router.push({
                    name : 'userService',
                    query : {
                        textType : type,
                        type : '1',
                    }
                })
            },
            numLogin() {
                if (checkAndroid()) {
                    codeLogin();
                } else if (checkIOS()) {
                    console.log("对ios传参");
                    window.webkit.messageHandlers.codeLogin.postMessage({});
                }
            },

            checkMoblie() {
                var re = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
                var phone = this.moblieValue;
                if(!re.test(phone)){
                    responseUtil.alertMsg(this,'手机格式有误')
                }
            },
    //返回上一页
    leftReturn() {
      this.$router.go(-1)
    },

        }

    }
</script>
<style scoped>

/* 输入框输入内容后背景色*/
    .login{
        width: 100%;
        padding-top: 50px;
    /* background: white; */
    }
    .loginIos{
        width: 100%;
        padding-top: 50px;
        background: white;
    }
    .indexImg{
        font-size: 15px;
        width: 1.3rem;
        height: 1.1rem;
    }
    .img_title{
        margin-top: 1.25rem;
        margin-left: 7.5%;
        width: 2rem;
        height: 1.5rem;
    }
    /*登录页面顶部文字*/
    .welcomeDiv{
        margin-bottom: 1.2rem;
        margin-left: 7.5%;

    }
    .welcomeDiv div{
        font-size: 26px;
        color: #222222;
    }
    /*手机号码输入错误信息提示*/
    .errorDiv{
        float: left;
        margin-left: 7.5%;
        height: 2rem;
        /*width: 8rem;*/
        /*margin-top: 1rem;*/
    }
    .errorInfo{
        padding-left: 0.15rem;
        padding-top: 0.15rem;
        padding-bottom: 0.2rem;
        margin: 0 auto;
        color:white;
        border-radius: 0.1rem;
        width: 2.5rem;
        background-color:black;
        opacity: 0.55;
        font-size: 12px;

    }
    /*属性placeholder样式*/
    input::-webkit-input-placeholder{
        color: #D8D8D8;
        font-family: PingFangSC-Regular;
        font-size: 15px;
        text-align: left;
        transform:translateY(5%);
    }

    .otherLogin{
        width: 8rem;
        margin-left: 7.5%;
        /*margin-bottom: 3rem;*/
        margin-top: 0.3rem;
    }
    /*点击密码登录*/
    .codeLogin{
        float: left;
        color: #ff5D3B;
        font-size: 13px;
        font-family: PingFangSC-Semibold;
        /*margin-bottom: 2rem;*/
    }
    /*忘记密码*/
    .forgetPwd{
        float: right;
        color: steelblue;
        font-size: 13px;
        font-family: PingFangSC-Semibold;
        /*margin-bottom: 2rem;*/
    }

    .loginBtn{
        font-weight: bold;
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: white;
        width: 92%;
        margin-left: 4%;
        height: 1.2rem;
        margin-bottom: 0.3rem;
        border: 0;
        border-radius: 0.15rem;/*圆角*/
        background:linear-gradient(to right,#ffc274,#ff5D3B) ;/*渐变色*/
    }
    .signUp{
        font-weight: bold;
        font-family: PingFangSC-Semibold;
        color: #ff5809;
        width: 92%;
        margin-left: 4%;
        height: 1.2rem;
        background-color: #f8f8f8;
        border: 0.03rem solid #ff5809;
        border-radius: 0.15rem;/*圆角*/
        font-size: 16px;
    }
    .btnDiv{
        margin-bottom: 1.5rem;
    }
    /*温馨提示样式*/
    .point{
        margin-top: 1rem;
        margin-left: 3%;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        width: 94%;
        font-size: 12px;
        color: #999999;
        text-indent: 0.6rem;/*首行缩进*/
    }
    .point span{
        color: steelblue;
    }
    /*竖线样式*/
    .line {
        padding-right: 0.15rem;
        padding-left: 0.15rem;
        color: #D8D8D8;
        font-size: 0.4111rem;
    }
    /*验证码输入框Div*/
    .inputCode {
        height:37.5px;
        background: #f8f8f8;
        width: 82%;
        border-bottom: 1PX solid #EFEFEF ;
        margin: 20px auto ;
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    /*手机号码输入框DIV*/
    .inputPhone{
        height:37.5px;
        background: #f8f8f8;
        width: 82%;
        border-bottom: 1PX solid #EFEFEF ;
        display: flex;
        align-items: center;
        margin: 0 auto;
        overflow: hidden;
    }
    /*手机号码输入框*/
    .inputPhone input {
        width:70%;
        vertical-align:middle;
        border: none;
        font-size: 16px;
        background: #f8f8f8 ;
    }
    /*验证码输入框*/
    .inputCode input {
        border: none;
        width:90%;
        font-size: 16px;
        background: #f8f8f8;
        margin-left: 0.3rem;
        /*box-shadow: none;*/
    }
    .label{
        color: #ff5809;
        font-size: 16px;
        margin-left: 0.3rem
    }
    /*input{*/
    /*    -webkit-box-shadow: 5px 5px 5px 1000px   #f8f8f8  inset !important;*/
    /*}*/
</style>
